import { OfferEvent } from '@r1-webui/gowholesale-offermanagement-v1'

export enum OfferHistoryGridHeaderType {
  Username,
  Text,
  Date,
}

export type OfferHistoryGridProps = {
  getOfferHistory: () => Promise<OfferEvent[]>
  onViewBiddingWar: (biddingWarId: string) => void
}
