import React from 'react'
import { BiddingWarStatus } from '@r1-webui/gowholesale-offermanagement-v1'
import { LabelType } from '@r1/ui-kit/contracts/ts/Label'
import { Label } from '@r1/ui-kit'

const getLabelTextByStatus = (status: BiddingWarStatus): string => {
  if (status === 'Active') {
    return 'Active'
  }

  return 'Expired'
}

const getLabelTypeByStatus = (status: BiddingWarStatus): LabelType => {
  if (status === 'Active') {
    return 'success'
  }

  return 'default'
}

export const OfferBiddingWarStatusLabel = React.memo(({ status }: { status: BiddingWarStatus }) => {
  const text = getLabelTextByStatus(status)
  const labelType = getLabelTypeByStatus(status)

  return <Label type={labelType}>{text}</Label>
})
