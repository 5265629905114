import React, { useState, useEffect, useMemo } from 'react'
import { Drawer, Flex, Loader, H2, Text, NotificationSystem, Box, Button } from '@r1/ui-kit'
import { BiddingWar } from '@r1-webui/gowholesale-offermanagement-v1'
import { handleServerError, useUserSettings } from '@r1/core-blocks'
import { ColumnSettings, DisplaySettings, Table } from '@r1/grid'
import { format } from 'date-fns'
import { offerManagementApi } from '../../../../api'
import { BiddingWarData } from '../../types'
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal'
import { GRID_DATE_FORMAT } from '../../const'
import {
  BiddingWarOffer,
  getBiddingWarOffersFromBiddingWarSummary,
  getCellRendererByColumn,
  gridColumnsData,
} from './lib'

type Props = {
  isOpen: boolean
  biddingWarData: BiddingWarData | null
  onBiddingWarCanceled: () => void
  onClose: () => void
}

export function BiddingWarDrawer({ isOpen, biddingWarData, onClose, onBiddingWarCanceled }: Props) {
  const currentUserFullName = useUserSettings().currentUser.userInfo?.fullName ?? 'Unknown'

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [biddingWar, setBiddingWar] = useState<BiddingWar | null>(null)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)

  const fetchBiddingWarById = async (biddingWarId: string) => {
    setIsLoading(true)

    const response = await offerManagementApi.getBiddingWar({
      biddingWarId,
    })

    if (response.status === 200) {
      setBiddingWar(response.body)
    }

    setIsLoading(false)
  }

  const cancelBiddingWar = async () => {
    if (biddingWarData) {
      setIsCanceling(true)

      const response = await offerManagementApi.cancelBiddingWar(
        { biddingWarId: String(biddingWarData.biddingWarId) },
        { canceledBy: currentUserFullName },
      )

      if (response.status === 200) {
        NotificationSystem.addNotification({
          level: 'success',
          message: 'Bidding war successfully canceled',
        })
        onBiddingWarCanceled()
      } else {
        handleServerError(response.body)
      }

      setIsCanceling(false)
    }
  }

  useEffect(() => {
    if (isOpen && biddingWarData) {
      fetchBiddingWarById(biddingWarData.biddingWarId)
    }
  }, [isOpen, biddingWarData])

  const displaySettings = useMemo<DisplaySettings<BiddingWarOffer>>(() => {
    const settings: ColumnSettings<BiddingWarOffer>[] = Array.from(gridColumnsData).map(
      ([column, columnSettings]) => ({
        width: columnSettings.width,
        minWidth: 100,
        header: {
          content: <div>{columnSettings.header}</div>,
        },
        cell: {
          $type: 'custom' as const,
          renderer: getCellRendererByColumn(column),
        },
      }),
    )

    return {
      showCounter: false,
      columnsSettings: settings,
    }
  }, [])

  const biddingWarOffers = useMemo<BiddingWarOffer[]>(
    () => (biddingWar ? getBiddingWarOffersFromBiddingWarSummary(biddingWar.summary) : []),
    [biddingWar],
  )

  return (
    <Drawer.Form
      title={`Bidding war #${biddingWarData?.biddingWarId}`}
      backdrop="closing"
      size={600}
      placement="right"
      show={isOpen}
      footer={
        biddingWar?.status === 'Active' ? (
          <Flex>
            <Button
              transparent
              loading={isCanceling}
              color="danger"
              onClick={() => setIsCancelDialogOpen(true)}
            >
              Cancel Bidding War
            </Button>
          </Flex>
        ) : null
      }
      onClose={onClose}
    >
      {!isLoading && biddingWar && biddingWarData ? (
        <Flex column>
          <H2>Product</H2>

          <Flex column spaceBetween="XS">
            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">Title</Text>
              </Box>

              <Text>{biddingWarData.productTitle}</Text>
            </Flex>

            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">SKU</Text>
              </Box>

              <Text>{biddingWarData.sku}</Text>
            </Flex>

            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">UPC</Text>
              </Box>

              <Text>{biddingWarData.upc}</Text>
            </Flex>

            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">Condition</Text>
              </Box>

              <Text>{biddingWarData.condition}</Text>
            </Flex>
          </Flex>

          <H2>Bidding War Summary</H2>
          <Flex column spaceBetween="XS">
            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">Status</Text>
              </Box>

              <Text>{biddingWar.status}</Text>
            </Flex>

            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">Created by</Text>
              </Box>

              <Text>{biddingWar.createdBy}</Text>
            </Flex>

            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">Minimum threshold price</Text>
              </Box>

              <Text>{biddingWar.minPrice}&nbsp;$</Text>
            </Flex>

            <Flex align="center">
              <Box minWidth={180}>
                <Text weight="medium">Bidding war end time</Text>
              </Box>

              <Text>{format(new Date(biddingWar.finishingAt), GRID_DATE_FORMAT)}</Text>
            </Flex>
          </Flex>

          <H2>Offers</H2>
          <Table data={biddingWarOffers} displaySettings={displaySettings} />
        </Flex>
      ) : (
        <Loader />
      )}

      <DialogModal
        show={isCancelDialogOpen}
        message={
          <>
            <Text type="paragraph">
              You are canceling bidding war #{biddingWar?.id} of {biddingWarOffers.length} offers
            </Text>
            <Text type="paragraph">Are you sure you want to cancel this bidding war?</Text>
          </>
        }
        onConfirm={() => {
          setIsCancelDialogOpen(false)
          cancelBiddingWar()
        }}
        onClose={() => setIsCancelDialogOpen(false)}
      />
    </Drawer.Form>
  )
}
