import {
  OfferFilter,
  OfferStatus,
  SortedColumn,
  SortType,
} from '@r1-webui/gowholesale-offermanagement-v1'
import { SortingType } from '@r1/grid/contracts/ts/DisplaySettings'
import { GridPagination, GridColumnSettings } from '../../types'
import { OffersGridHeaderType } from './types'

export const DEFAULT_PAGE_SIZE = 100

export const PAGE_SIZES: number[] = [25, 50, DEFAULT_PAGE_SIZE]

export const DEFAULT_PAGINATION: GridPagination = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
}

export const DEFAULT_FILTER: OfferFilter = {
  statuses: ['Active', 'Accepted', 'CounterOffer'],
}

export const OFFER_STATUSES_FOR_APPROVE: OfferStatus[] = ['Active', 'CounterOffer']

export const OFFER_STATUSES_FOR_REJECT: OfferStatus[] = ['Active', 'CounterOffer']

export const OFFER_STATUSES_FOR_COUNTER_OFFER: OfferStatus[] = ['Active', 'CounterOffer']

export const OFFER_STATUSES_FOR_BIDDING_WAR: OfferStatus[] = ['Active', 'CounterOffer']

export const FINISHED_OFFER_STATUSES: OfferStatus[] = [
  'Approved',
  'Accepted',
  'Expired',
  'Cancelled',
  'Rejected',
  'Completed',
]

export const sortingColumnsHeaders = [
  OffersGridHeaderType.Id,
  OffersGridHeaderType.Status,
  OffersGridHeaderType.Sku,
  OffersGridHeaderType.Offer,
  OffersGridHeaderType.ExpirationDate,
  OffersGridHeaderType.SellersCounterOffer,
  OffersGridHeaderType.ApprovedDate,
  OffersGridHeaderType.OfferUnits,
  OffersGridHeaderType.ApprovedBy,
  OffersGridHeaderType.CancellationDate,
  OffersGridHeaderType.SellersCounterOfferUnits,
  OffersGridHeaderType.Upc,
  OffersGridHeaderType.Facility,
]

export const sortingFieldsHeadersMap: Record<SortedColumn, OffersGridHeaderType> = {
  Id: OffersGridHeaderType.Id,
  Status: OffersGridHeaderType.Status,
  Sku: OffersGridHeaderType.Sku,
  CustomerOfferPrice: OffersGridHeaderType.Offer,
  ExpirationDate: OffersGridHeaderType.ExpirationDate,
  SellerOfferPrice: OffersGridHeaderType.SellersCounterOffer,
  ApprovalDate: OffersGridHeaderType.ApprovedDate,
  OfferUnits: OffersGridHeaderType.OfferUnits,
  ApprovedBy: OffersGridHeaderType.ApprovedBy,
  CancellationDate: OffersGridHeaderType.CancellationDate,
  SellerCounterUnits: OffersGridHeaderType.SellersCounterOfferUnits,
  Upc: OffersGridHeaderType.Upc,
  FacilityId: OffersGridHeaderType.Facility,
}

export const sortingTypeFilterUiMap: Record<SortType, SortingType> = {
  Ascending: 'asc',
  Descending: 'desc',
}

export const sortingTypeUiFilterMap: Record<SortingType, SortType> = {
  asc: 'Ascending',
  desc: 'Descending',
  none: 'Ascending',
}

export const offersGridHeaders = new Map<OffersGridHeaderType, GridColumnSettings>([
  [OffersGridHeaderType.Id, { header: 'ID', width: 100 }],
  [OffersGridHeaderType.Status, { header: 'Status', width: 130 }],
  [OffersGridHeaderType.BiddingWarStatus, { header: 'Bidding War', width: 110 }],
  [OffersGridHeaderType.BiddingWarEndTime, { header: 'Bidding War End Time', width: 180 }],
  [OffersGridHeaderType.BiddingWarMinPrice, { header: 'Minimum Threshold Price', width: 160 }],
  [OffersGridHeaderType.CompanyName, { header: 'Company Name', width: 270 }],
  [OffersGridHeaderType.CompanyPhone, { header: 'Company Phone', width: 160 }],
  [OffersGridHeaderType.Product, { header: 'Product', width: 300 }],
  [OffersGridHeaderType.Sku, { header: 'Sku', width: 180 }],
  [OffersGridHeaderType.Upc, { header: 'Upc', width: 180 }],
  [OffersGridHeaderType.Ask, { header: 'Ask', width: 130 }],
  [OffersGridHeaderType.Offer, { header: 'Offer', width: 130 }],
  [OffersGridHeaderType.OfferUnits, { header: 'Offer Units', width: 100 }],
  [OffersGridHeaderType.OfferExtendedValue, { header: 'Offer Extended Value', width: 130 }],
  [OffersGridHeaderType.HighestActiveOffer, { header: 'Highest Active Offer', width: 160 }],
  [OffersGridHeaderType.LowestActiveOffer, { header: 'Lowest Active Offer', width: 160 }],
  [OffersGridHeaderType.Facility, { header: 'Facility', width: 100 }],
  [OffersGridHeaderType.RemainingUnits, { header: 'Remaining Units', width: 160 }],
  [OffersGridHeaderType.LastSale, { header: 'Last Sale', width: 130 }],
  [OffersGridHeaderType.Recovery, { header: '% of Ask', width: 130 }],
  [OffersGridHeaderType.ExpirationDate, { header: 'Expiration Date', width: 200 }],
  [OffersGridHeaderType.CancellationDate, { header: 'Cancellation Date', width: 200 }],
  [OffersGridHeaderType.SellersCounterOffer, { header: "Seller's Counter Offer", width: 200 }],
  [
    OffersGridHeaderType.SellersCounterOfferUnits,
    { header: "Seller's Counter Offer Units", width: 160 },
  ],
  [OffersGridHeaderType.OrderId, { header: 'Order ID', width: 100 }],
  [OffersGridHeaderType.ApprovedDate, { header: 'Date Approved', width: 200 }],
  [OffersGridHeaderType.ApprovedBy, { header: 'Approved By', width: 140 }],
  [
    OffersGridHeaderType.LastTwoWeekAverageProductPrice,
    { header: 'L2W Avg $ price B2C', width: 130 },
  ],
  [OffersGridHeaderType.LastTwoWeekUnitsSold, { header: 'L2W unit sales B2C', width: 100 }],
  [OffersGridHeaderType.ActiveBasketSize, { header: 'Active Basket Size', width: 130 }],
  [OffersGridHeaderType.Actions, { header: '', width: 20 }],
])

export const offerStatusNameMap: Record<OfferStatus, string> = {
  Accepted: 'Accepted',
  Active: 'Active',
  Approved: 'Approved',
  Cancelled: 'Cancelled',
  AcceptFailed: 'Cancelled',
  Completed: 'Completed',
  CounterOffer: 'Counter Offer',
  Expired: 'Expired',
  AcceptExpired: 'Expired',
  Outdated: 'Expired',
  Rejected: 'Rejected',
  CancelAccepted: 'Cancelled',
  RejectAccepted: 'Cancelled',
}

export const OFFER_STATUS_OPTIONS: { id: OfferStatus; name: string }[] = [
  { id: 'Active', name: offerStatusNameMap.Active },
  { id: 'Approved', name: offerStatusNameMap.Approved },
  { id: 'CounterOffer', name: offerStatusNameMap.CounterOffer },
  { id: 'Accepted', name: offerStatusNameMap.Accepted },
  { id: 'Expired', name: offerStatusNameMap.Expired },
  { id: 'Cancelled', name: offerStatusNameMap.Cancelled },
  { id: 'Rejected', name: offerStatusNameMap.Rejected },
  { id: 'Completed', name: offerStatusNameMap.Completed },
]

export const GWS_SKU_REGEXP = /GWS\d{12,13}[A-Z]{1,2}/

export const GRID_DATE_FORMAT = 'MM/dd/yyyy hh:mm a'

export const RECENT_OFFERS_TIMESPAN_DAYS = 14
