import React from 'react'
import { MenuItem } from '@r1/ui-kit/contracts/ts/Dropdown'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { Flex, Text } from '@r1/ui-kit'
import {
  getOfferCounterActionAbility,
  getOffersApprovalActionAbility,
  getOffersRejectActionAbility,
} from '../../utils'
import { OfferAction } from '../../types'
import { FINISHED_OFFER_STATUSES, OFFER_STATUSES_FOR_BIDDING_WAR } from '../../const'

export function getOfferMenuItems(offer: Offer, allowedToEdit: boolean): MenuItem<OfferAction>[] {
  const items: MenuItem<OfferAction>[] = []

  const isOfferFinished = FINISHED_OFFER_STATUSES.includes(offer.status)

  if (!isOfferFinished && allowedToEdit) {
    const approvalAbility = getOffersApprovalActionAbility([offer])
    const counterOfferAbility = getOfferCounterActionAbility([offer])
    const rejectAbility = getOffersRejectActionAbility([offer])

    items.push(
      {
        id: OfferAction.Approve,
        disabled: !approvalAbility.canPerform,
        title: (
          <Flex column>
            <Text>Approve</Text>
            {!approvalAbility.canPerform ? (
              <Text type="caption" maxLines={2}>
                {approvalAbility.reasonMessage}
              </Text>
            ) : null}
          </Flex>
        ),
      },
      {
        id: OfferAction.Counter,
        disabled: !counterOfferAbility.canPerform,
        title: (
          <Flex column>
            <Text>Counter Offer</Text>
            {!counterOfferAbility.canPerform ? (
              <Text type="caption" maxLines={2}>
                {counterOfferAbility.reasonMessage}
              </Text>
            ) : null}
          </Flex>
        ),
      },
      {
        id: OfferAction.Reject,
        disabled: !rejectAbility.canPerform,
        title: (
          <Flex column>
            <Text>Reject</Text>
            {!rejectAbility.canPerform ? (
              <Text type="caption" maxLines={2}>
                {rejectAbility.reasonMessage}
              </Text>
            ) : null}
          </Flex>
        ),
      },
    )
  }

  if (offer.finishedBiddingWarId || offer.activeBiddingWar) {
    items.push({
      id: OfferAction.ViewBiddingWar,
      title: <Text>Bidding War Summary</Text>,
    })
  } else if (OFFER_STATUSES_FOR_BIDDING_WAR.includes(offer.status)) {
    items.push({
      id: OfferAction.StartBiddingWar,
      title: (
        <Flex column>
          <Text>Start Bidding War</Text>
        </Flex>
      ),
    })
  }

  return items
}
