import React from 'react'
import { format } from 'date-fns'
import { Flex, Icon, Link, Text, Tooltip } from '@r1/ui-kit'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { Palette } from '@r1/ui-kit/contracts/ts/theming'
import { GridColumnSettings } from '../../../../types'
import { GRID_DATE_FORMAT } from '../../const'
import { OFFERS_FILTER_OFFER_ID_QUERY_KEY } from '../../../../const'
import { ROUTES } from '../../../../../../navigation'

export enum GridColumn {
  OfferId,
  Facility,
  OfferQuantity,
  OfferPrice,
  ExpirationTime,
}

export const gridColumnsData = new Map<GridColumn, GridColumnSettings>([
  [GridColumn.OfferId, { header: 'Offer ID' }],
  [GridColumn.Facility, { header: 'Facility', width: 200 }],
  [GridColumn.OfferQuantity, { header: 'Offer Quantity', width: 150 }],
  [GridColumn.OfferPrice, { header: 'Offer Price', width: 100 }],
  [GridColumn.ExpirationTime, { header: 'Expiration Time', width: 200 }],
])

export const getCellRendererByColumn = (
  column: GridColumn,
  invalidOfferIds: string[],
  palette: Palette,
) => {
  switch (column) {
    case GridColumn.OfferId:
      return (offer: Offer) => {
        const searchParams = new URLSearchParams()
        searchParams.set(OFFERS_FILTER_OFFER_ID_QUERY_KEY, offer.id)

        return (
          <Flex spaceBetween="XXS" align="center">
            <Link target="_blank" href={`${ROUTES.OFFER_MANAGEMENT}?${searchParams.toString()}`}>
              {offer.id}
            </Link>
            {invalidOfferIds.includes(offer.id) ? (
              <Tooltip text="Offer is excluded from the Bidding war because the Bidding war end time is greater than the expiration time">
                <Icon type="warning" color={palette.red[700]} />
              </Tooltip>
            ) : null}
          </Flex>
        )
      }

    case GridColumn.Facility:
      return (offer: Offer) => <Text>{offer.statistic.targetStock.facilityName} </Text>
    case GridColumn.ExpirationTime:
      return (offer: Offer) => {
        const expirationDate = new Date(offer.expiringOn)
        return <Text>{format(expirationDate, GRID_DATE_FORMAT)}</Text>
      }
    case GridColumn.OfferPrice:
      return (offer: Offer) => <Text>${offer.price.amount}</Text>
    case GridColumn.OfferQuantity:
      return (offer: Offer) => <Text>{offer.quantity.value}</Text>
    default:
      return () => null
  }
}
