import React, { useState, useEffect, useMemo } from 'react'
import { addHours, addMinutes, compareAsc, formatISO } from 'date-fns'
import { useTheme } from '@emotion/react'
import { ColumnSettings, DisplaySettings, Table } from '@r1/grid'
import {
  Drawer,
  Flex,
  Loader,
  H2,
  FormField,
  NumericInput,
  Text,
  Button,
  NotificationSystem,
  Tooltip,
} from '@r1/ui-kit'
import { NewBiddingWar, Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { handleServerError, useUserSettings } from '@r1/core-blocks'
import { offerManagementApi } from '../../../../api'
import { NewBiddingWarData } from '../../types'
import { OFFER_STATUSES_FOR_BIDDING_WAR } from '../../const'
import { getCellRendererByColumn, gridColumnsData } from './lib'

type Props = {
  isOpen: boolean
  newBiddingWarData: NewBiddingWarData | null
  onClose: () => void
  onBiddingWarCreated: () => void
}

const MIN_DURATION_HOURS = 1
const MAX_DURATION_HOURS = 23
const MIN_DURATION_MINUTES = 0
const MAX_DURATION_MINUTES = 59
const MIN_PRICE = 1
const MIN_OFFERS_COUNT = 2

export function NewBiddingWarDrawer({
  isOpen,
  newBiddingWarData,
  onClose,
  onBiddingWarCreated,
}: Props) {
  const { palette } = useTheme()
  const currentUserFullName = useUserSettings().currentUser.userInfo?.fullName ?? 'Unknown'

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [offers, setOffers] = useState<Offer[]>([])
  const [durationHours, setDurationHours] = useState<string | null>(String(MIN_DURATION_HOURS))
  const [durationMinutes, setDurationMinutes] = useState<string | null>(
    String(MIN_DURATION_MINUTES),
  )
  const [price, setPrice] = useState<string | null>(String(MIN_PRICE))
  const [invalidOfferIds, setInvalidOfferIds] = useState<string[]>([])
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const reset = () => {
    setOffers([])
    setDurationHours(String(MIN_DURATION_HOURS))
    setDurationMinutes(String(MIN_DURATION_MINUTES))
    setPrice(String(MIN_PRICE))
    setInvalidOfferIds([])
  }

  const fetchOffersBySku = async (sku: string): Promise<void> => {
    setIsLoading(true)

    const response = await offerManagementApi.getOffers({
      filter: { offerSkus: [sku], statuses: OFFER_STATUSES_FOR_BIDDING_WAR },
      pagination: { page: 1, pageSize: 100 },
    })

    if (response.status === 200) {
      setOffers(response.body.offers)
    } else {
      setOffers([])
    }

    setIsLoading(false)
  }

  const onDurationHoursChange = (payload: string | null) => {
    let result = Number(payload)

    if (result < MIN_DURATION_HOURS) {
      result = MIN_DURATION_HOURS
    }
    if (result > MAX_DURATION_HOURS) {
      result = MAX_DURATION_HOURS
    }

    setDurationHours(String(result))
  }

  const onDurationMinutesChange = (payload: string | null) => {
    let result = Number(payload)

    if (result < MIN_DURATION_MINUTES) {
      result = MIN_DURATION_MINUTES
    }
    if (result > MAX_DURATION_MINUTES) {
      result = MAX_DURATION_MINUTES
    }

    setDurationMinutes(String(result))
  }

  const onPriceChange = (payload: string | null) => {
    let result = Number(payload)

    if (result < MIN_PRICE) {
      result = MIN_PRICE
    }

    setPrice(String(result))
  }

  useEffect(() => {
    if (isOpen && newBiddingWarData) {
      fetchOffersBySku(newBiddingWarData.sku)
    }
  }, [isOpen, newBiddingWarData])

  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])

  useEffect(() => {
    let maxExpiraitionDate = new Date(Date.now())
    maxExpiraitionDate = addHours(maxExpiraitionDate, Number(durationHours))
    maxExpiraitionDate = addMinutes(maxExpiraitionDate, Number(durationMinutes))

    const ids = offers
      .filter(offer => {
        const expirationDate = new Date(offer.expiringOn)
        return compareAsc(expirationDate, maxExpiraitionDate) === -1
      })
      .map(offer => offer.id)

    setInvalidOfferIds(ids)
  }, [durationMinutes, durationHours, offers])

  const priceError = useMemo<string | null>(() => {
    if (price && newBiddingWarData && Number(price) > Number(newBiddingWarData.maxPrice)) {
      return 'Counter offer price should be less than ask price'
    }
    return null
  }, [newBiddingWarData, price])

  const offersCountError = useMemo<string | null>(() => {
    if (offers.length < MIN_OFFERS_COUNT) {
      return `There must be at least ${MIN_OFFERS_COUNT} active offers to start Bidding War`
    }

    return null
  }, [offers])

  const displaySettings = useMemo<DisplaySettings<Offer>>(() => {
    const settings: ColumnSettings<Offer>[] = Array.from(gridColumnsData).map(
      ([column, columnSettings]) => ({
        width: columnSettings.width,
        minWidth: 100,
        header: {
          content: <div>{columnSettings.header}</div>,
        },
        cell: {
          $type: 'custom' as const,
          renderer: getCellRendererByColumn(column, invalidOfferIds, palette),
        },
      }),
    )

    return {
      showCounter: false,
      columnsSettings: settings,
    }
  }, [invalidOfferIds, palette])

  const isFormValid = useMemo<boolean>(
    () => !priceError && !offersCountError,
    [offersCountError, priceError],
  )

  const createBiddingWar = async () => {
    if (isFormValid && durationHours && durationMinutes && price && newBiddingWarData) {
      setIsCreating(true)

      let finishingAtDate = new Date(Date.now())
      finishingAtDate = addHours(finishingAtDate, Number(durationHours))
      finishingAtDate = addMinutes(finishingAtDate, Number(durationMinutes))

      const body: NewBiddingWar = {
        masterSku: newBiddingWarData.masterSku,
        sku: newBiddingWarData.sku,
        minPrice: Number(price),
        finishingAt: formatISO(finishingAtDate),
        createdBy: currentUserFullName,
      }

      const response = await offerManagementApi.createBiddingWar(body)
      if (response.status === 200) {
        NotificationSystem.addNotification({
          level: 'success',
          message: 'Bidding war successfully created',
        })
        onBiddingWarCreated()
      } else {
        handleServerError(response.body)
      }

      setIsCreating(false)
    }
  }

  return (
    <Drawer.Form
      title="Start Bidding War"
      backdrop="closing"
      size={1000}
      placement="right"
      show={isOpen}
      footer={
        <Flex justify="space-between">
          <Tooltip text={offersCountError} disabled={!offersCountError}>
            <Button loading={isCreating} disabled={!isFormValid} onClick={createBiddingWar}>
              Start Bidding War
            </Button>
          </Tooltip>
        </Flex>
      }
      onClose={onClose}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Flex column>
          <H2>Offers</H2>
          <Table height={200} data={offers} displaySettings={displaySettings} />

          <H2>Conditions</H2>
          <Flex spaceBetween="L">
            <FormField>
              <FormField.Label>Duration (Hours : Minutes)</FormField.Label>
              <Flex spaceBetween="XXS" align="center">
                <NumericInput value={durationHours} onChange={onDurationHoursChange} />
                <Text>:</Text>
                <NumericInput value={durationMinutes} onChange={onDurationMinutesChange} />
              </Flex>
            </FormField>

            <FormField>
              <FormField.Label>Minimum threshold price, $</FormField.Label>
              <NumericInput
                error={Boolean(priceError)}
                minFractionDigits={2}
                maxFractionDigits={2}
                value={price}
                onChange={onPriceChange}
              />
              <FormField.Error>{priceError}</FormField.Error>
            </FormField>
          </Flex>
        </Flex>
      )}
    </Drawer.Form>
  )
}
