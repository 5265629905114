import React from 'react'
import { Modal } from '@r1/ui-kit'
import { OfferEvent } from '@r1-webui/gowholesale-offermanagement-v1'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { OfferHistoryGrid } from '../../OfferHistoryGrid/OfferHistoryGrid'

type OfferHistoryModalProps = {
  show: boolean
  getOfferHistory: () => Promise<OfferEvent[]>
  onViewBiddingWar: (biddingWarId: string) => void
  onClose: () => void
}

export const OfferHistoryModal = ({
  show,
  getOfferHistory,
  onViewBiddingWar,
  onClose,
}: OfferHistoryModalProps) => {
  const modalActionButtons: ControlledActionButtons = React.useMemo(
    () => [
      {
        title: 'Close',
        onClick: onClose,
        align: 'right',
      },
    ],
    [onClose],
  )

  return (
    <Modal
      isControlled
      size="L"
      show={show}
      title="Offer History"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onClose}
    >
      <OfferHistoryGrid getOfferHistory={getOfferHistory} onViewBiddingWar={onViewBiddingWar} />
    </Modal>
  )
}
