import React from 'react'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { Button, Dropdown, Flex, Icon } from '@r1/ui-kit'
import { MenuItem } from '@r1/ui-kit/contracts/ts/Dropdown'
import { OfferAction } from '../../types'
import { getOfferMenuItems } from './lib'

export function OfferMenu({
  offer,
  allowedToEdit,
  onActionClick,
}: {
  offer: Offer
  allowedToEdit: boolean
  onActionClick: (offer: Offer, action: OfferAction) => void
}) {
  const menuItems = React.useMemo<MenuItem<OfferAction>[]>(
    () => getOfferMenuItems(offer, allowedToEdit),
    [offer, allowedToEdit],
  )

  return (
    <Flex basis={1} align="center" justify="space-between">
      <Icon
        type="history"
        cursor="pointer"
        onClick={() => onActionClick(offer, OfferAction.History)}
      />

      {menuItems.length ? (
        <Dropdown.Menu
          items={menuItems}
          mode="click"
          placement="bottom-end"
          onSelectItem={itemId => {
            onActionClick(offer, itemId)
          }}
        >
          <Flex justify="center">
            <Button transparent shape="square" size="S">
              <Icon type="dots" />
            </Button>
          </Flex>
        </Dropdown.Menu>
      ) : null}
    </Flex>
  )
}
