import { OfferManagement } from '@r1-webui/gowholesale-offermanagement-v1'
import { handleServerError } from '@r1/core-blocks'
import { NotificationSystem } from '@r1/ui-kit'

export const showApproveOfferErrorMessages = ({
  wrongVersionOffers,
  notEnoughQuantityOffers,
  alreadyAcceptedOffers,
  alreadyRejectedOffers,
  alreadyExpiredOffers,
}: {
  wrongVersionOffers: string[]
  notEnoughQuantityOffers: string[]
  alreadyAcceptedOffers: string[]
  alreadyRejectedOffers: string[]
  alreadyExpiredOffers: string[]
}) => {
  if (wrongVersionOffers.length) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: `Unfortunately, the approval of the offers failed: ${wrongVersionOffers.join(
        ', ',
      )}. Please refresh the page and try again.`,
    })
  }

  if (notEnoughQuantityOffers.length) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: `Not enough products to approve all these offers: ${notEnoughQuantityOffers.join(
        ', ',
      )}. Only some of them can be approved.`,
    })
  }

  if (alreadyAcceptedOffers.length) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: `Offer(s) ${alreadyAcceptedOffers.join(', ')} already accepted`,
    })
  }

  if (alreadyRejectedOffers.length) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: `Offer(s) ${alreadyRejectedOffers.join(', ')} already rejected`,
    })
  }

  if (alreadyExpiredOffers.length) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: `Offer(s) ${alreadyExpiredOffers.join(', ')} already expired`,
    })
  }
}

export const handleApproveOfferReponse = (response: OfferManagement.ApproveOfferResponse) => {
  if (response.status === 200) {
    const {
      wrongVersionOffers,
      notEnoughQuantityOffers,
      alreadyAcceptedOffers,
      alreadyRejectedOffers,
      alreadyExpiredOffers,
    } = response.body

    const failedOffersCount =
      wrongVersionOffers.length +
      notEnoughQuantityOffers.length +
      alreadyAcceptedOffers.length +
      alreadyRejectedOffers.length +
      alreadyExpiredOffers.length

    if (failedOffersCount) {
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Success',
        message: 'Part of the offers have successfully approved',
      })

      showApproveOfferErrorMessages(response.body)
    } else {
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Success',
        message: 'Offers have successfully approved',
      })
    }
  } else if (response.status === 409) {
    showApproveOfferErrorMessages(response.body)
  } else {
    handleServerError(response)
  }
}
