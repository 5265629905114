import React from 'react'
import { Link, Text } from '@r1/ui-kit'
import { BiddingWarSummary } from '@r1-webui/gowholesale-offermanagement-v1'
import { GridColumnSettings } from '../../../../types'
import { OFFERS_FILTER_OFFER_ID_QUERY_KEY } from '../../../../const'
import { ROUTES } from '../../../../../../navigation'

export enum OfferBiddingWarStatus {
  active,
  accepted,
  countered,
  rejected,
  failed,
}

export enum GridColumn {
  OfferId,
  BiddingWarStatus,
}

export type BiddingWarOffer = {
  id: string
  biddingWarStatus: OfferBiddingWarStatus
}

export const gridColumnsData = new Map<GridColumn, GridColumnSettings>([
  [GridColumn.OfferId, { header: 'Offer ID' }],
  [GridColumn.BiddingWarStatus, { header: 'Bidding War Status', width: 200 }],
])

export const biddingWarOfferStatusName: Record<OfferBiddingWarStatus, string> = {
  [OfferBiddingWarStatus.active]: 'Active',
  [OfferBiddingWarStatus.accepted]: 'Accepted',
  [OfferBiddingWarStatus.countered]: 'Counter Offer',
  [OfferBiddingWarStatus.rejected]: 'Rejected',
  [OfferBiddingWarStatus.failed]: 'Failed',
}

export const getCellRendererByColumn = (column: GridColumn) => {
  switch (column) {
    case GridColumn.OfferId:
      return (offer: BiddingWarOffer) => {
        const searchParams = new URLSearchParams()
        searchParams.set(OFFERS_FILTER_OFFER_ID_QUERY_KEY, offer.id)
        return (
          <Link target="_blank" href={`${ROUTES.OFFER_MANAGEMENT}?${searchParams.toString()}`}>
            {offer.id}
          </Link>
        )
      }
    case GridColumn.BiddingWarStatus:
      return (offer: BiddingWarOffer) => (
        <Text>{biddingWarOfferStatusName[offer.biddingWarStatus]} </Text>
      )
    default:
      return () => null
  }
}

export const getBiddingWarOffersFromBiddingWarSummary = (
  biddingWarSuammary: BiddingWarSummary,
): BiddingWarOffer[] => {
  const activeOffers: BiddingWarOffer[] =
    biddingWarSuammary.active?.map(id => ({
      id: String(id),
      biddingWarStatus: OfferBiddingWarStatus.active,
    })) || []

  const counteredOffers: BiddingWarOffer[] =
    biddingWarSuammary.countered?.map(id => ({
      id: String(id),
      biddingWarStatus: OfferBiddingWarStatus.countered,
    })) || []

  const rejectedOffers: BiddingWarOffer[] =
    biddingWarSuammary.rejected?.map(id => ({
      id: String(id),
      biddingWarStatus: OfferBiddingWarStatus.rejected,
    })) || []

  const acceptedOffers: BiddingWarOffer[] =
    biddingWarSuammary.accepted?.map(id => ({
      id: String(id),
      biddingWarStatus: OfferBiddingWarStatus.accepted,
    })) || []

  const failedOffers: BiddingWarOffer[] =
    biddingWarSuammary.failed?.map(id => ({
      id: String(id),
      biddingWarStatus: OfferBiddingWarStatus.failed,
    })) || []

  return [
    ...activeOffers,
    ...counteredOffers,
    ...rejectedOffers,
    ...acceptedOffers,
    ...failedOffers,
  ]
}
